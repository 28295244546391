<template>
  <div>
    <div class="row g-0 text-center">
      <div class="col-2 ">
        <BIconArrowLeftShort v-if="order.state > 0" role="button" @click="sub()" class="icon" />
      </div>
      <div class="col">
        {{ ORDER.TEXT[order.state] }}
      </div>
      <div class="col-2">
        <BIconArrowRightShort
          v-if="order.state < ORDER.TEXT.length - 1"
          role="button"
          @click="add()"
          class="icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep"
import icons from "../../assets/icons"
import { apiSetOrder } from "../../services/api"
import { ORDER } from "../../services/enums"
import { useStore } from "@/store/store"

export default {
  name: "OrderState",
  props: ["order"],
  setup() {
    return {
      ORDER,
    }
  },
  components: {
    ...icons,
  },
  methods: {
    add() {
      let orderCopy = cloneDeep(useStore().state.collections.orders[this.order.id])
      orderCopy.state += 1
      apiSetOrder(orderCopy)
    },
    sub() {
      let orderCopy = cloneDeep(useStore().state.collections.orders[this.order.id])
      orderCopy.state -= 1
      apiSetOrder(orderCopy)
    },
  },
}
</script>
