<template>
  <Container class="mb-5">
    <Header>
      {{ popBrew.formatCount }}
      {{ popBrew.recipeName }}
      <template #right>
        <BadgeBtn class="mx-2" @click="openBrew(popBrew.id)">
          <BIconPencilSquare />
        </BadgeBtn>
      </template>
    </Header>

    <PageContent>
      <template #right>
        <BrewRecyme :popBrew="popBrew" />
      </template>
    </PageContent>
    <FermentationGraph :popBrew="popBrew" />
    <FermentationLog />
    <TapTable :popBrew="popBrew" />
    <SellingTable :popOrders="popOrders" class="my-5" />
  </Container>
</template>

<script>
import FermentationGraph from "../components/fermenting/FermentationGraph.vue"
import FermentationLog from "../components/fermenting/FermentationLog.vue"
import BrewRecyme from "../components/recymes/BrewRecyme.vue"
import TapTable from "../components/tapping/TapTable.vue"
import SellingTable from "../components/selling/SellingTable.vue"

import comp from "../components/base"
import icons from "../assets/icons"

import { mapActions } from "vuex"
import { computed } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "@/store/store"

export default {
  name: "BrewDetail",
  components: {
    ...comp,
    ...icons,
    FermentationGraph,
    BrewRecyme,
    FermentationLog,
    TapTable,
    SellingTable,
  },
  setup() {
    let popBrew = computed(() => useStore().getters.popBrew(useRoute().params.id))
    return {
      popBrew,
      popOrders: computed(() =>
        popBrew.value.orderIds.map(orderId => useStore().getters.popOrder(orderId))
      ),
    }
  },
  methods: {
    ...mapActions(["openBrew", "openBottling"]),
  },
}
</script>
