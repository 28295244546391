<template>
  <CardGray header="Selling Table" v-model="search" placeholder="search (buyer)">
    <Table class="table-hover" v-if="popOrders.length">
      <template v-slot:headRow>
        <TH></TH>
        <THSort :sort="state.category" @click="sortNum('category')">Action</THSort>
        <THSort :sort="state.name" @click="sortStr('name')"> Status</THSort>
        <THSort :sort="state.supplierName" @click="sortStr('supplierName')">Køber</THSort>
        <THSort :sort="state.total" @click="sortNum('total')"> Shipping </THSort>
        <THSort :sort="state.ebs" @click="sortNum('ebs')"> Itmes</THSort>
      </template>
      <template v-slot:body>
        <TR v-for="(order, index) in searched" :key="index">
          <TD>
            <ButtonGroup>
              <Button @click="openOrder(order.id)"><BIconPencilSquare /></Button>
              <Button @click="openDeleteOrder(order.id)"><BIconTrash /></Button>
            </ButtonGroup>
          </TD>
          <TD>
            <ButtonLink name="SellingSheet" :params="order.id">
              Selling sheet
            </ButtonLink>
          </TD>
          <TD><OrderState :order="order"/></TD>
          <TD>
            {{ order.formatCount }} <strong> {{ order.buyerName }} </strong></TD
          >
          <TD> {{ order.shippingText }}</TD>
          <TD>
            <span
              class="badge bg-dark p-2 mx-2"
              v-for="(item, index) in orderItem(order.inventory)"
              :key="index"
            >
              {{ item }}
            </span>
          </TD>
        </TR>
      </template>
    </Table>
  </CardGray>
</template>

<script>
import comp from "../base"
import Fuse from "fuse.js"
import { mapActions } from "vuex"
import icons from "../../assets/icons"
import OrderState from "../base/OrderState.vue"
import CardGray from "../base/CardGray.vue"

export default {
  name: "OrderTable",
  props: ["popOrders"],
  components: {
    ...comp,
    ...icons,
    OrderState,
    CardGray,
  },
  data() {
    return {
      search: "",
      state: {},
    }
  },
  computed: {
    fuse() {
      return new Fuse(this.popOrders, {
        keys: ["buyerName"],
      })
    },
    searched() {
      return this.search ? this.fuse.search(this.search).map(item => item.item) : this.popOrders
    },
  },
  methods: {
    ...mapActions(["openOrder"]),
    ...mapActions(["openDeleteOrder"]),

    sortNum(key) {
      if (this.state[key]) this.searched.sort((a, b) => a[key] - b[key])
      else this.searched.sort((a, b) => b[key] - a[key])
      this.state[key] = !this.state[key]
    },
    sortStr(key) {
      this.searched.sort((a, b) => {
        let val = String(a[key]).localeCompare(b[key])
        return this.state[key] ? val : val * -1
      })
      this.state[key] = !this.state[key]
    },
    orderItem(item) {
      return item.map(
        item =>
          item.brewName +
          " | " +
          item.quantity +
          " " +
          item.containerName +
          " " +
          item.containerSize +
          item.containerUnitText
      )
    },
  },
}
</script>
